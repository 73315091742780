import * as React from 'react'

import { Text } from '@chakra-ui/react'

import { Layout, Hero } from '../components/PageLayout'
import SEO from '../components/SEO'

const CoverageMapLazy = React.lazy(() => import('../components/CoverageMap'))

const CoverageMapPage = () => {
  const isSSR = typeof window === 'undefined'

  return (
    <Layout>
      <SEO title="Coverage" description="Check whether you can expect 4G coverage from DavMobile in your area." />
      <Hero title="Our coverage" text="Providing cellular connection throughout David's neighbourhood." />

      <Text my={4} fontSize="lg" fontWeight="bold">
        Our coverage map is coming soon.
      </Text>

      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <CoverageMapLazy />
        </React.Suspense>
      )}

      <Text my={4} fontSize={'sm'}>
        We cannot guarantee the accuracy of this data. Many factors affect mobile network coverage, including atmospheric conditions, congestion,
        maintenance, whether I can be bothered to turn on the core and more. This is intended as guidance only.
      </Text>
    </Layout>
  )
}

export default CoverageMapPage
